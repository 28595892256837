import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function OrderItemTable({ orders }) {

  return (
    <TableContainer style={{ width: '60vw' }} component={Paper}>
      <Table sx={{ minWidth: 650, maxWidth: '60vw' }} aria-label="simple table">
        <TableHead >
          <TableRow style={{ background: '#8FB5A1', color: '#fff', height: '10px' }}>
            <TableCell style={{ color: '#fff', fontSize: 14 }}></TableCell>
            <TableCell style={{ color: '#fff', fontSize: 14 }} align="start">Барааны нэр</TableCell>
            {/* <TableCell style={{ color: '#fff', fontSize: 14}} align="center">Төлөв</TableCell> */}
            <TableCell style={{ color: '#fff', fontSize: 14 }} align="center">Нэгж үнэ</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 14 }} align="center">Тоо ширхэг</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 14 }} align="center">Нийт дүн</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row, index) => (
            // row.order.map((e) => (
            <TableRow style={{ background: '#f0f0f0' }} className='hover'
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {/* <TableCell align="left">{index + 1}</TableCell> */}
              {/* <TableCell component="th" scope="row">
                  {row.phone} <br /> {row.address}
                </TableCell> */}
              <TableCell style={{ color: '#fff', fontSize: 14 }}></TableCell>
              <TableCell align="start">{row.name}</TableCell>
              <TableCell align="center">{new Intl.NumberFormat('En-us').format(row.price)}</TableCell>
              <TableCell align="center">{row.unit}</TableCell>
              <TableCell align="center">{new Intl.NumberFormat('En-us').format(row.unitPrice)}</TableCell>
            </TableRow>
            // ))
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}