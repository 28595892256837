import React, { useEffect, useState } from 'react'
// import ContainerAddTable from '../Table/AddTable/ContainerAddTable'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IoTrashBin } from "react-icons/io5";
import axios from 'axios';

const MinusContainerModal = ({ closeModal }) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [container, setContainer] = useState('');
  const [location, setLocation] = useState('');
  const [shop, setShop] = useState('');
  const [shops, setShops] = useState([]);
  const [inputs, setInputs] = useState([{ name: "", comment: '', unit: "", reportId: '', staticUnit: '', status: 'Зарлага' }]);
  // const id = useId();

  const userInfo = JSON.parse(window.localStorage.getItem('useridturbo'));

  useEffect(() => {
    if (isLoading) {
      axios.get('https://dev.turbodelivery.mn/post')
        .then((e) => {
          // setIsLoading(false);
          setItems(e.data);
          axios.get('https://dev.turbodelivery.mn/shop')
            .then((e) => {
              setIsLoading(false);
              setShops(e.data.data);
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    }
  }, [isLoading]);

  const createContainer = () => {
    axios.post('https://dev.turbodelivery.mn/container/minus', {
      item: inputs,
      canceled: true,
      container: container,
      location: location,
      shop: shop,
      user: userInfo._id,
    })
      .then(() => {
        window.location.reload(false);
        closeModal(false);
      })
      .catch((e) => console.log(e))
  }

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = value;

    // Update staticUnit to the same value as unit
    onChangeValue[index]['staticUnit'] = onChangeValue[index]['unit'];

    setInputs(onChangeValue);
  };


  const handleAddInput = () => {
    setInputs([...inputs, { name: "", unit: "", reportId: "", staticUnit: '', comment: '' }]);
  }

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  return (
    <div className='blur_container'>
      <div className='modal' style={{ width: '53vw' }}>
        <div className='two-line'>
          <h2>Агуулахаас бараа хасах</h2>
          <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
        </div>
        <br />
        <label>
          Агуулах сонгох:
          <select onChange={(e) => setContainer(e.target.value)} name="" id="">
            <option value="">Сонгох</option>
            <option value="2">2</option>
            <option value="1">1</option>
          </select>
        </label>
        <label>
          Агуулах байршил:
          <select onChange={(e) => setLocation(e.target.value)} name="" id="">
            <option value="">Сонгох</option>
            <option value="2">2</option>
            <option value="1">1</option>
          </select>
        </label>
        <label>
          Дэлгүүр сонгох:
          <select onChange={(e) => setShop(e.target.value)} name="" id="">
            <option value="">Сонгох</option>
            {/* <option value="2">2</option> */}
            {isLoading == false ? shops.map((e) => (
              <option value={e.name}>{e.name}</option>
            )) : <></>}
          </select>
        </label>
        <button onClick={() => handleAddInput()} className='button-white'>Мөр Нэмэх</button>
        {/* <ContainerAddTable unit_arrey={inputs}/> */}
        <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
          <Table sx={{ minWidth: '40vw', maxWidth: '50vw' }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                <TableCell style={{ color: '#fff', fontSize: 16 }}>Бараа</TableCell>
                <TableCell style={{ color: '#fff', fontSize: 16 }}>Тоо хэмжээ</TableCell>
                <TableCell style={{ color: '#fff', fontSize: 16 }}>Тайлбар</TableCell>
                <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inputs.map((row, index) => (
                <TableRow
                  className='hover'
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={index}
                >
                  <TableCell component="th" scope="row">
                    <select name="name" id="" onChange={(e) => handleChange(e, index)}>
                      <option value="">Сонгох</option>
                      {items.map((e) => (
                        <option key={e.id} value={e.title}>{e.title}</option>
                      ))}
                    </select>
                  </TableCell>
                  <TableCell align="center">
                    <input type="number" name='unit' onChange={(e) => handleChange(e, index)} id="" />
                  </TableCell>
                  <TableCell align="center">
                    <input type="text" name='comment' onChange={(e) => handleChange(e, index)} id="" />
                  </TableCell>
                  <TableCell align="center">
                    {/* uildel */}
                    {/* <input style={{visibility: 'hidden'}} type="number" name='staticUnit' onChange={(e) => handleChange(e, index)} id="" /> */}
                    <IoTrashBin size={30} color='red' onClick={handleDeleteInput} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <div className="body"> {JSON.stringify(inputs)} </div> */}
        <div className='button_container'>
          <button onClick={createContainer} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
          <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
        </div>
      </div>
    </div>
  )
}

export default MinusContainerModal;
