import React, { useEffect, useState } from 'react'
import styles from './Sidebar.module.css';
import { NavLink } from 'react-router-dom';
import icon from '../../Assets/logo.png'
import home from '../../Assets/sidebar_icons/home.svg'
import bill from '../../Assets/sidebar_icons/bill.svg'
import container from '../../Assets/sidebar_icons/container.svg'
import check from '../../Assets/sidebar_icons/check.svg'
import order from '../../Assets/sidebar_icons/order.svg'
import info from '../../Assets/sidebar_icons/info.svg'
import shop from '../../Assets/sidebar_icons/shop.svg'
import driver from '../../Assets/sidebar_icons/driver.svg'
import undriver from '../../Assets/sidebar_icons/unselect_driver.svg'
import unhome from '../../Assets/sidebar_icons/unselect_home.svg'
import unbill from '../../Assets/sidebar_icons/unselect_bill.svg'
import uncontainer from '../../Assets/sidebar_icons/unselect_container.svg'
import uncheck from '../../Assets/sidebar_icons/unselect_check.svg'
import unorder from '../../Assets/sidebar_icons/unselect_order.svg'
import uninfo from '../../Assets/sidebar_icons/unselect_info.svg'
import unshop from '../../Assets/sidebar_icons/unselect_shop.svg'
import { RiMenuUnfoldFill } from "react-icons/ri";
import { RxCross1 } from "react-icons/rx";
// import { IoMdHome } from "react-icons/io";

const Sidebar = () => {
  const sideId = parseInt(window.localStorage.getItem('side-id'));

  const [select, setSelect] = useState(sideId);
  const [miniSelect, setMiniSelect] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // useEffect(() => {

  // }, [sideId])

  return (
    <>
      {/* {!isSidebarOpen ? <RiMenuUnfoldFill size={30} onClick={() => setIsSidebarOpen(!isSidebarOpen)} /> : null}
      {isSidebarOpen ? <div className={styles.container}>
      </div> : null} */}

      {window.screen.width < 769 ?
        !isSidebarOpen ? <RiMenuUnfoldFill className={styles.menu_icon}
          size={30} onClick={() => setIsSidebarOpen(true)} /> :
          <RxCross1 className={styles.menu_icon} color='white'
            size={30} onClick={() => setIsSidebarOpen(false)} /> : null}

      {
        isSidebarOpen || window.screen.width > 768 ?
          <div className={styles.container}>
            <img src={icon} className={styles.logo} alt="" />
            {/* <br /> */}
            {/* <br /> */}
            <NavLink to={'/dashboard'} onClick={() => {
              setSelect(0);
              setIsSidebarOpen(false);
            }} className={select === 0 ? styles.activelink : styles.link}>
              <img src={select === 0 ? unhome : home} className={styles.icon} alt={home} /> Хянах самбар
            </NavLink>
            <NavLink to={'/order'} onClick={() => {
              setSelect(1);
              setIsSidebarOpen(false);
            }} className={select === 1 ? styles.activelink : styles.link}>
              <img src={select === 1 ? unorder : order} className={styles.icon} alt={home} />  Захиалга
            </NavLink>
            <NavLink to={'/finance'} onClick={() => {
              setSelect(2);
              setIsSidebarOpen(false);
            }} className={select === 2 ? styles.activelink : styles.link}>
              <img src={select === 2 ? unbill : bill} className={styles.icon} alt={home} /> Тооцоо
            </NavLink>
            <NavLink to={'/container'} onClick={() => {
              setSelect(3);
              setIsSidebarOpen(false);
            }} className={select === 3 ? styles.activelink : styles.link}>
              <img src={select === 3 ? uncontainer : container} className={styles.icon} alt={home} />  Агуулах бараа
            </NavLink>
            <div>
              <NavLink
                to={'/reportcontainer'}
                onClick={() => {
                  setSelect(4);
                  setIsSidebarOpen(false);
                }}
                className={select === 4 ? styles.activelink : styles.link}
              ><img src={select === 4 ? uncheck : check} className={styles.icon} alt={home} /> Тайлан</NavLink>
              {select === 4 ? <div className={styles.link_container}>
                <NavLink to={'/reportcontainer'} onClick={() => setMiniSelect(1)} className={
                  (miniSelect === 1 || select === 4) && miniSelect !== 2 ? styles.activeMinilink : styles.miniLink}>
                  Агуулах
                </NavLink>
                <NavLink to={'/reportshop'} onClick={() => setMiniSelect(2)} className={miniSelect === 2 ? styles.activeMinilink : styles.miniLink}>
                  Дэлгүүр тооцоо
                </NavLink>
              </div> : <></>}
            </div>
            <div>
              <NavLink
                to={'/items'}
                onClick={() => {
                  setSelect(5);
                  setIsSidebarOpen(false);
                }}
                className={select === 5 ? styles.activelink : styles.link}
              ><img src={select === 5 ? uninfo : info} className={styles.icon} alt={home} />Лавлах</NavLink>
              {select === 5 ? <div className={styles.link_container}>
                <NavLink to={'/items'} onClick={() => setMiniSelect(3)} className={
                  (miniSelect === 3 || select === 5) && miniSelect !== 4 ? styles.activeMinilink : styles.miniLink}>
                  Бараа
                </NavLink>
                <NavLink to={'/category'} onClick={() => setMiniSelect(4)} className={miniSelect === 4 ? styles.activeMinilink : styles.miniLink}>
                  Барааны бүлэг
                </NavLink>
              </div> : <></>}
            </div>
            <NavLink to={'/shop'} onClick={() => {
              setSelect(6);
              setIsSidebarOpen(false);
            }} className={select === 6 ? styles.activelink : styles.link}>
              <img src={select === 6 ? unshop : shop} className={styles.icon} alt={home} /> Дэлгүүр</NavLink>
          </div> : null}

    </>
  )
}

export default Sidebar
