import React, { useEffect, useState } from 'react';
import styles from './Container.module.css';
import ContainerTable from '../../Utills/Table/ContainerTable';
import Navbar from '../../Components/Navbar/Navbar';
import ContainerAddModal from '../../Utills/Modal/ContainerAddModal';
import axios from 'axios';
import MinusContainerModal from '../../Utills/Modal/MinusModal/MinusContainerModal';
import add_icon from '../../Assets/uttils/add_button.svg';

const Container = () => {
  const [isModal, setIsModal] = useState(false);
  const [minusModal, setMinusModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [containers, setContainers] = useState([]);
  const [status, setStatus] = useState('');

  const userInfo = JSON.parse(window.localStorage.getItem('useridturbo'));

  const closeModal = () => {
    setIsModal(false);
    setMinusModal(false);
  };

  useEffect(() => {
    console.log(userInfo);
    const fetchData = async () => {
      try {
        window.localStorage.setItem('side-id', 3);
        const containerResponse = await axios.get(`https://dev.turbodelivery.mn/container?sort=-createdAt&user=${userInfo._id}`);
        setContainers(containerResponse.data.data);

        // const userResponse = await axios.get(`https://dev.turbodelivery.mn/user?_id=66cf8701937962631e0f026a`);
        // setShop(userResponse.data.data);

      } catch (error) {
        alert(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isLoading) {
      fetchData();
    }
  }, [isLoading]);

  return (
    <>
      {!isModal ? <Navbar title={'Агуулах бараа'} /> : null}
      {isModal ? <div className='abs'><ContainerAddModal closeModal={closeModal} /></div> : null}
      {minusModal ? <div className='abs'><MinusContainerModal closeModal={closeModal} /></div> : null}
      {isLoading ? <div className="loader-container"><div className='loader'></div></div> : null}
      <div className={styles.container}>
        <div className='line-start'>
          <button onClick={() => setIsModal(true)} className='button'><img src={add_icon} className='icon' alt="" /> Нэмэх</button>
        </div>
        <ContainerTable containers={containers} filterStatus={status} user={userInfo} />
      </div>
    </>
  );
};

export default Container;
