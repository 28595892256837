import axios from 'axios';
import React, { useEffect, useState } from 'react'

const ItemsEditModal = ({ closeModal, id }) => {
    const [categories, setCategories] = useState([]);
    const [shops, setShops] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState('');
    const [file, setFile] = useState('');
    const [desc, setDesc] = useState('');
    const [category, setCategory] = useState('');
    const [shop, setShop] = useState('');
    const [price, setPrice] = useState('');
    // const { id } = useParams();

    const userInfo = JSON.parse(window.localStorage.getItem('useridturbo'));

    useEffect(() => {
        if (isLoading) {
            axios.get('https://dev.turbodelivery.mn/post/' + id)
                .then((e) => {
                    setDesc(e.data.description);
                    setName(e.data.title);
                    setPrice(e.data.price);
                })
            axios.get('https://dev.turbodelivery.mn/categories')
                .then((e) => {
                    setCategories(e.data.data);
                    // setIsLoading(false);
                    axios.get('https://dev.turbodelivery.mn/shop')
                        .then((e) => {
                            setShops(e.data.data);
                            setIsLoading(false);
                        })
                        .catch((e) => console.log(e));
                })
                .catch((e) => console.log(e));
        }
    }, [isLoading])

    async function createNewPost(ev) {
        ev.preventDefault();

        const data = new FormData();
        data.append('file', file[0]);
        data.append('title', name);
        data.append('description', desc);
        data.append('category', category);
        data.append('shop', shop);
        data.append('price', price);
        data.append('user', userInfo._id);

        axios.put('https://dev.turbodelivery.mn/post', data)
            .then(() => {
                window.location.reload(false);
                closeModal(false);
                setIsLoading(false);
            }).catch((e) => alert(e));
    }

    return (
        <div className='blur_container'>
            <div className='modal'>
                <div className='two-line'>
                    <h2>Бараа нэмэх</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <label>
                    Бүлэг сонгох:
                    <select onChange={(e) => setCategory(e.target.value)}>
                        <option>Cонгох</option>
                        {categories.map((e) => (
                            <option value={e.name}>{e.name}</option>
                        ))}

                    </select>
                </label>
                <label>
                    Дэлгүүр сонгох:
                    <select onChange={(e) => setShop(e.target.value)}>
                        <option>Cонгох</option>
                        {shops.map((e) => (
                            <option value={e.name}>{e.name}</option>
                        ))}

                    </select>
                </label>
                <label>
                    Барааны нэр:
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
                <label>
                    Үнэ:
                    <input
                        type="text"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                    />
                </label>
                <label>
                    Барааны нэр:
                    <input
                        type="file"
                        // value={file}
                        onChange={(e) => setFile(e.target.files)}
                    />
                </label>
                <label>
                    Тайлбар:
                    <textarea value={desc} onChange={(e) => setDesc(e.target.value)} cols="50" rows="5"></textarea>
                </label>
                <div className='button_container'>
                    <button onClick={createNewPost} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
                    <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                </div>
            </div>
        </div>
    )
}

export default ItemsEditModal
