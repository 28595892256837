import React from 'react'
import OrderItemTable from '../../Table/OrderItemTable'

const OrderItemDetialModal = ({ orders, closeModal }) => {
    return (
        <div className='blur_container'>
            <div className="modal" style={{ width: '60vw' }}>
                <div className="two-line" style={{ width: '60vw' }}>
                    <h2>Захиалгын бараанууд</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal} >X</h2>
                </div>
                <OrderItemTable orders={orders} />
            </div>
        </div>
    )
}

export default OrderItemDetialModal
