import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CiEdit } from "react-icons/ci";
import dayjs from 'dayjs';

export default function DriverHistoryTable({ orders, drivers, edit, user }) {

    return (
        <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
            <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
                <TableHead>
                    <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                        <TableCell className='table-title' style={{ color: '#fff', width: '20px' }}>Id</TableCell>
                        <TableCell className='table-title' style={{ color: '#fff', width: '170px' }}>Утас / Хаяг</TableCell>
                        <TableCell className='table-title' style={{ color: '#fff', width: '100px' }}>Харилцагч</TableCell>
                        <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Төлөв</TableCell>
                        <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Нийт дүн</TableCell>
                        <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Огноо</TableCell>
                        <TableCell className='table-title' style={{ color: '#fff', width: '250px' }} align="center">Жолооч</TableCell>
                        <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Үйлдэл</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders
                        .map((row, index) => (
                            <TableRow className='hover'
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            // style={{position: 'absolute', display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center'}} 
                            >
                                <TableCell className='table-item' align="left">{index + 1}</TableCell>
                                <TableCell className='table-item' component="th" scope="row">
                                    {row.phone} <br /> {row.address}
                                </TableCell>
                                <TableCell className='table-item' align="center">{user.filter((e) => e._id.includes(row.user)).map((e) => (
                                    e.name
                                ))}</TableCell>
                                <TableCell className='table-item' align="center">{row.status}</TableCell>
                                <TableCell className='table-item' align="center">{new Intl.NumberFormat('en-Us').format(row.price)}</TableCell>
                                <TableCell className='table-item' style={{ width: '100px' }} align="center">{dayjs(row.createdAt).format('YYYY-MM-DD')}</TableCell>
                                <TableCell className='table-item' align="center" >
                                    <div style={{ width: '280px', display: 'flex', alignItems: 'center', gap: '25px', justifyContent: 'center' }}>
                                        {
                                            drivers.filter((e) => e._id.includes(row.driver)).map((e) => (
                                                <p>{e.name}</p>
                                            ))
                                        }
                                    </div>
                                </TableCell>
                                <TableCell className='table-item' align="center">

                                    <CiEdit
                                        onClick={() => edit({ id: row._id })}
                                        size={30} style={{ paddingRight: '30px', cursor: 'pointer' }} color='blue'
                                    />
                                    {/* <FaPen
                                        onClick={() => edit({ id: row._id })}
                                        size={20} style={{ paddingRight: '30px' }} color='gold'
                                    /> */}
                                    {/* <MdDeleteForever onClick={() => deleteOrder({id: row._id})} color='red' size={35} /> */}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}