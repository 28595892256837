import React, { useEffect, useState } from 'react'
import styles from './Login.module.css';
import img from '../../Assets/Login.svg'
import icon from '../../Assets/icon.svg'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [isSnackBar, setIsSnackBar] = useState(false);
  const [roleError, setRoleError] = useState(false);

  const userCache = JSON.parse(window.localStorage.getItem('useridturbo'));

  useEffect(() => {
    if (userCache && userCache.name !== null) {
      // <Navigate to={'/dashboard'} replace={true}/>
      navigate("/dashboard");
    }
  }, [userCache]);

  const login = () => {
    axios.post('https://dev.turbodelivery.mn/user/login', {
      email: email,
      password: password,
    })
      .then((e) => {
        // setUser(e.data.data);
        if (e.data.user.role === 'user') {
          window.localStorage.setItem('useridturbo', JSON.stringify(e.data.user));
          window.location.reload(false);
        } else {
          setRoleError(true);
        }
      })
      .catch(() => setIsSnackBar(true));
  }

  const handleClose = () => {
    setIsSnackBar(false);
    setRoleError(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.img_item}>
        <img src={img} alt="" />
      </div>
      <div className={styles.login}>
        <img src={icon} alt="" />
        <div>
          {/* <p>{}</p> */}
          {/* <Alert severity="error">=
            This is an error Alert with a scary title.
          </Alert> */}
          <Snackbar className={styles.snackbar} open={isSnackBar}
            autoHideDuration={10000} onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert
              onClose={handleClose}
              severity="error"
              // variant="filled"
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row', // Display in a row
                alignItems: 'center', // Center items vertically
              }}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              iconMapping={{ vertical: 'top', horizontal: 'right' }}
              className={styles.alert}
            >
              Имэйл эсвэл нууц үг буруу байна.
            </Alert>
          </Snackbar>
          <Snackbar className={styles.snackbar} open={roleError}
            autoHideDuration={10000} onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert
              onClose={handleClose}
              severity="error"
              // variant="filled"
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row', // Display in a row
                alignItems: 'center', // Center items vertically
              }}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              iconMapping={{ vertical: 'top', horizontal: 'right' }}
              className={styles.alert}
            >
              Зөвхөн админ эрхээр нэвтрэх боломжтой.
            </Alert>
          </Snackbar>
          <label style={{ alignItems: 'center' }}>
            <p>Нэвтрэх нэр</p>
            <input
              type="email"
              name=""
              id=""
              placeholder='Нэвтрэх нэр'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label style={{ alignItems: 'center' }}>
            <p>Нууц үг</p>
            <input
              type="password"
              name=""
              id=""
              placeholder='Нууц үг'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
        </div>
        <button onClick={login} style={{ width: window.screen.width > 768 ? '14vw' : '40vw' }} className='button'>Нэвтрэх</button>
      </div>
    </div>
  )
}

export default Login
