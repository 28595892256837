import React, { useEffect, useState } from 'react'
import styles from './Category.module.css'
import CategoryTable from '../../../Utills/Table/CategoryTable'
import Navbar from '../../../Components/Navbar/Navbar'
import CategoryAddModal from '../../../Utills/Modal/CategoryAddModal'
import axios from 'axios'
import add_icon from '../../../Assets/uttils/add_button.svg'

const Category = () => {
  const [isModal, setIsModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isLoad, setIsLoad] = useState(true);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('');

  const userInfo = JSON.parse(window.localStorage.getItem('useridturbo'));

  useEffect(() => {
    if (isLoad) {
      window.localStorage.setItem('side-id', 5);
      axios.get('https://dev.turbodelivery.mn/categories?user=' + userInfo._id)
        .then((e) => {
          setCategories(e.data.data);
          setIsLoad(false);
        })
        .catch((e) => console.log(e));
    }
  }, [isLoad])

  const closeModal = () => {
    setIsModal(false);
  }
  return (
    <>
      {!isModal ? <Navbar title={'Барааны бүлэг'} /> : <></>}
      {isModal ? <CategoryAddModal closeModal={closeModal} /> : <></>}
      {isLoad ? <div className='loader-container'><div className="loader"></div></div> : <></>}
      <div className={styles.container}>
        <div className="space-between" style={{ justifyContent: 'space-between', paddingRight: '2vw' }}>
          <button onClick={() => setIsModal(true)} className='button'><img src={add_icon} className='icon' alt="" /> Нэмэх</button>
        </div>
        <CategoryTable user={user} users={users} categories={categories} />
      </div>
    </>
  )
}

export default Category
