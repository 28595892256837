export default function UbData() {
    return {
        "Орон нутаг": [],
        "Баянзүрх": [
            "1-р хороо",
            "2-р хороо",
            "3-р хороо",
            "4-р хороо",
            "5-р хороо",
            "6-р хороо",
            "7-р хороо",
            "8-р хороо",
            "9-р хороо",
            "10-р хороо",
            "11-р хороо",
            "12-р хороо",
            "13-р хороо",
            "14-р хороо",
            "15-р хороо",
            "16-р хороо",
            "17-р хороо",
            "18-р хороо",
            "19-р хороо",
            "20-р хороо",
            "21-р хороо",
            "22-р хороо",
            "23-р хороо",
            "24-р хороо",
            "25-р хороо",
            "26-р хороо",
            "27-р хороо",
            "28-р хороо",
            "29-р хороо",
            "30-р хороо",
            "31-р хороо",
            "32-р хороо",
            "33-р хороо",
            "34-р хороо",
            "35-р хороо",
            "36-р хороо",
            "37-р хороо",
            "38-р хороо",
            "39-р хороо",
            "40-р хороо",
            "41-р хороо",
            "42-р хороо",
            "43-р хороо",
            "44-р хороо",
            "45-р хороо",
            "46-р хороо",
            "47-р хороо",
            "48-р хороо",
            "49-р хороо",
            "50-р хороо",
        ],
        "Сүхбаатар": [
            "1-р хороо",
            "2-р хороо",
            "3-р хороо",
            "4-р хороо",
            "5-р хороо",
            "6-р хороо",
            "7-р хороо",
            "8-р хороо",
            "9-р хороо",
            "10-р хороо",
            "11-р хороо",
            "12-р хороо",
            "13-р хороо",
            "14-р хороо",
            "15-р хороо",
            "16-р хороо",
            "17-р хороо",
            "18-р хороо",
            "19-р хороо",
            "20-р хороо",
            "21-р хороо",
            "22-р хороо",
            "23-р хороо",
            "24-р хороо",
            "25-р хороо",
            "26-р хороо",
            "27-р хороо",
            "28-р хороо",
            "29-р хороо",
            "30-р хороо",
            "31-р хороо",
            "32-р хороо",
            "33-р хороо",
            "34-р хороо",
            "35-р хороо",
            "36-р хороо",
            "37-р хороо",
            "38-р хороо",
            "39-р хороо",
            "40-р хороо",
            "41-р хороо",
            "42-р хороо",
            "43-р хороо",
            "44-р хороо",
            "45-р хороо",
            "46-р хороо",
            "47-р хороо",
            "48-р хороо",
            "49-р хороо",
            "50-р хороо",
        ],
        "Сонгинохайрхан": [
            "1-р хороо",
            "2-р хороо",
            "3-р хороо",
            "4-р хороо",
            "5-р хороо",
            "6-р хороо",
            "7-р хороо",
            "8-р хороо",
            "9-р хороо",
            "10-р хороо",
            "11-р хороо",
            "12-р хороо",
            "13-р хороо",
            "14-р хороо",
            "15-р хороо",
            "16-р хороо",
            "17-р хороо",
            "18-р хороо",
            "19-р хороо",
            "20-р хороо",
            "21-р хороо",
            "22-р хороо",
            "23-р хороо",
            "24-р хороо",
            "25-р хороо",
            "26-р хороо",
            "27-р хороо",
            "28-р хороо",
            "29-р хороо",
            "30-р хороо",
            "31-р хороо",
            "32-р хороо",
            "33-р хороо",
            "34-р хороо",
            "35-р хороо",
            "36-р хороо",
            "37-р хороо",
            "38-р хороо",
            "39-р хороо",
            "40-р хороо",
            "41-р хороо",
            "42-р хороо",
            "43-р хороо",
            "44-р хороо",
            "45-р хороо",
            "46-р хороо",
            "47-р хороо",
            "48-р хороо",
            "49-р хороо",
            "50-р хороо",
        ],
        "Баянгол": [
            "1-р хороо",
            "2-р хороо",
            "3-р хороо",
            "4-р хороо",
            "5-р хороо",
            "6-р хороо",
            "7-р хороо",
            "8-р хороо",
            "9-р хороо",
            "10-р хороо",
            "11-р хороо",
            "12-р хороо",
            "13-р хороо",
            "14-р хороо",
            "15-р хороо",
            "16-р хороо",
            "17-р хороо",
            "18-р хороо",
            "19-р хороо",
            "20-р хороо",
            "21-р хороо",
            "22-р хороо",
            "23-р хороо",
            "24-р хороо",
            "25-р хороо",
            "26-р хороо",
            "27-р хороо",
            "28-р хороо",
            "29-р хороо",
            "30-р хороо",
            "31-р хороо",
            "32-р хороо",
            "33-р хороо",
            "34-р хороо",
            "35-р хороо",
            "36-р хороо",
            "37-р хороо",
            "38-р хороо",
            "39-р хороо",
            "40-р хороо",
            "41-р хороо",
            "42-р хороо",
            "43-р хороо",
            "44-р хороо",
            "45-р хороо",
            "46-р хороо",
            "47-р хороо",
            "48-р хороо",
            "49-р хороо",
            "50-р хороо",
        ],
        "Хан-Уул": [
            "1-р хороо",
            "2-р хороо",
            "3-р хороо",
            "4-р хороо",
            "5-р хороо",
            "6-р хороо",
            "7-р хороо",
            "8-р хороо",
            "9-р хороо",
            "10-р хороо",
            "11-р хороо",
            "12-р хороо",
            "13-р хороо",
            "14-р хороо",
            "15-р хороо",
            "16-р хороо",
            "17-р хороо",
            "18-р хороо",
            "19-р хороо",
            "20-р хороо",
            "21-р хороо",
            "22-р хороо",
            "23-р хороо",
            "24-р хороо",
            "25-р хороо",
            "26-р хороо",
            "27-р хороо",
            "28-р хороо",
            "29-р хороо",
            "30-р хороо",
            "31-р хороо",
            "32-р хороо",
            "33-р хороо",
            "34-р хороо",
            "35-р хороо",
            "36-р хороо",
            "37-р хороо",
            "38-р хороо",
            "39-р хороо",
            "40-р хороо",
            "41-р хороо",
            "42-р хороо",
            "43-р хороо",
            "44-р хороо",
            "45-р хороо",
            "46-р хороо",
            "47-р хороо",
            "48-р хороо",
            "49-р хороо",
            "50-р хороо",
        ],
        "Чингэлтэй": [
            "1-р хороо",
            "2-р хороо",
            "3-р хороо",
            "4-р хороо",
            "5-р хороо",
            "6-р хороо",
            "7-р хороо",
            "8-р хороо",
            "9-р хороо",
            "10-р хороо",
            "11-р хороо",
            "12-р хороо",
            "13-р хороо",
            "14-р хороо",
            "15-р хороо",
            "16-р хороо",
            "17-р хороо",
            "18-р хороо",
            "19-р хороо",
            "20-р хороо",
            "21-р хороо",
            "22-р хороо",
            "23-р хороо",
            "24-р хороо",
            "25-р хороо",
            "26-р хороо",
            "27-р хороо",
            "28-р хороо",
            "29-р хороо",
            "30-р хороо",
            "31-р хороо",
            "32-р хороо",
            "33-р хороо",
            "34-р хороо",
            "35-р хороо",
            "36-р хороо",
            "37-р хороо",
            "38-р хороо",
            "39-р хороо",
            "40-р хороо",
            "41-р хороо",
            "42-р хороо",
            "43-р хороо",
            "44-р хороо",
            "45-р хороо",
            "46-р хороо",
            "47-р хороо",
            "48-р хороо",
            "49-р хороо",
            "50-р хороо",
        ],
        "Налайх": [
            "1-р хороо",
            "2-р хороо",
            "3-р хороо",
            "4-р хороо",
            "5-р хороо",
            "6-р хороо",
            "7-р хороо",
            "8-р хороо",
            "9-р хороо",
            "10-р хороо",
            "11-р хороо",
            "12-р хороо",
            "13-р хороо",
            "14-р хороо",
            "15-р хороо",
            "16-р хороо",
            "17-р хороо",
            "18-р хороо",
            "19-р хороо",
            "20-р хороо",
            "21-р хороо",
            "22-р хороо",
            "23-р хороо",
            "24-р хороо",
            "25-р хороо",
            "26-р хороо",
            "27-р хороо",
            "28-р хороо",
            "29-р хороо",
            "30-р хороо",
            "31-р хороо",
            "32-р хороо",
            "33-р хороо",
            "34-р хороо",
            "35-р хороо",
            "36-р хороо",
            "37-р хороо",
            "38-р хороо",
            "39-р хороо",
            "40-р хороо",
            "41-р хороо",
            "42-р хороо",
            "43-р хороо",
            "44-р хороо",
            "45-р хороо",
            "46-р хороо",
            "47-р хороо",
            "48-р хороо",
            "49-р хороо",
            "50-р хороо",
        ],
        "Багануур": [
            "1-р хороо",
            "2-р хороо",
            "3-р хороо",
            "4-р хороо",
            "5-р хороо",
            "6-р хороо",
            "7-р хороо",
            "8-р хороо",
            "9-р хороо",
            "10-р хороо",
            "11-р хороо",
            "12-р хороо",
            "13-р хороо",
            "14-р хороо",
            "15-р хороо",
            "16-р хороо",
            "17-р хороо",
            "18-р хороо",
            "19-р хороо",
            "20-р хороо",
            "21-р хороо",
            "22-р хороо",
            "23-р хороо",
            "24-р хороо",
            "25-р хороо",
            "26-р хороо",
            "27-р хороо",
            "28-р хороо",
            "29-р хороо",
            "30-р хороо",
            "31-р хороо",
            "32-р хороо",
            "33-р хороо",
            "34-р хороо",
            "35-р хороо",
            "36-р хороо",
            "37-р хороо",
            "38-р хороо",
            "39-р хороо",
            "40-р хороо",
            "41-р хороо",
            "42-р хороо",
            "43-р хороо",
            "44-р хороо",
            "45-р хороо",
            "46-р хороо",
            "47-р хороо",
            "48-р хороо",
            "49-р хороо",
            "50-р хороо",
        ],
        "Багахангай": [
            "1-р хороо",
            "2-р хороо",
            "3-р хороо",
            "4-р хороо",
            "5-р хороо",
            "6-р хороо",
            "7-р хороо",
            "8-р хороо",
            "9-р хороо",
            "10-р хороо",
            "11-р хороо",
            "12-р хороо",
            "13-р хороо",
            "14-р хороо",
            "15-р хороо",
            "16-р хороо",
            "17-р хороо",
            "18-р хороо",
            "19-р хороо",
            "20-р хороо",
            "21-р хороо",
            "22-р хороо",
            "23-р хороо",
            "24-р хороо",
            "25-р хороо",
            "26-р хороо",
            "27-р хороо",
            "28-р хороо",
            "29-р хороо",
            "30-р хороо",
            "31-р хороо",
            "32-р хороо",
            "33-р хороо",
            "34-р хороо",
            "35-р хороо",
            "36-р хороо",
            "37-р хороо",
            "38-р хороо",
            "39-р хороо",
            "40-р хороо",
            "41-р хороо",
            "42-р хороо",
            "43-р хороо",
            "44-р хороо",
            "45-р хороо",
            "46-р хороо",
            "47-р хороо",
            "48-р хороо",
            "49-р хороо",
            "50-р хороо",
        ]
    }
}