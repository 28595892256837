import React from 'react'

const DeleteModal = ({ text, click, closeModal }) => {
    return (
        <div className='blur_container'>
            <div className="modal" style={{ width: '25vw', alignItems: 'center' }}>
                <div className="line">
                    <h2 style={{ color: 'red' }}>{text}</h2>
                </div>
                <p>Та уг үйлдлийг хийхдээ итгэлтэй байна уу?</p>
                <div className='button_container' style={{ width: '25vw', justifyContent: 'space-between' }}>
                    <button onClick={click} className='button-white' style={{ color: '#2c9961' }}>Тийм</button>
                    <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Цуцлах</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteModal
