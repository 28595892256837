import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import TablePagination from '@mui/material/TablePagination'; // Энэ мөрөнд TablePagination компонентийг нэмнэ

export default function ReportShopTable({ finance, endValue, startValue, user, users, orders, depositsuccess, deposits }) {
  const [orderDate, setOrderDate] = React.useState('');
  const [isModal, setIsModal] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0); // Энэ мөрөнд page-г нэмнэ
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Энэ мөрөнд setPage функцыг дуудаж 0-р тогтоож өгнө
  };

  const calculateTotalPrice = ({ savedDate }) => {
    // alert(dayjs(savedDate))
    return orders
      // .filter((e) => e.savedDate === savedDate)
      .filter((el) => dayjs(el.orderDate).subtract(8, 'hour').format('YYYY-MM-DD').toString().includes(savedDate))
      .filter((item) =>
      (((dayjs(dayjs(item.orderDate).subtract(16, 'hour')).isBefore(dayjs(startValue)) || dayjs(dayjs(item.orderDate).subtract(16, 'hour')).isSame(startValue, 'date')))
        &&
        (dayjs(dayjs(item.orderDate).subtract(16, 'hour')).isAfter(dayjs(endValue)) || (dayjs(dayjs(item.orderDate).subtract(16, 'hour')).isSame(endValue, 'date'))
        ))
      )
      .reduce((total, report) => {
        return (
          total +
          (report.status === 'Хүргэсэн' || report.status === 'Хэсэгчлэн хүргэсэн' ?
            report.price : 0
          )
        );
      }, 0);
  };

  const calculateTotalDelivered = ({ date }) => {
    return orders
      // .filter((e) => user ? (e.user && e.user === user) : e)
      .filter((el) => dayjs(el.orderDate).subtract(8, 'hour').format('YYYY-MM-DD').toString().includes(date))
      .filter((el) => el.status === 'Хүргэсэн' || el.status === 'Хэсэгчлэн хүргэсэн' || el.status === 'Хаягаар очсон')
      .filter((item) => (
        ((dayjs(dayjs(item.orderDate).subtract(16, 'hour')).isBefore(dayjs(startValue)) || dayjs(dayjs(item.orderDate).subtract(16, 'hour')).isSame(startValue, 'date')))
        &&
        (dayjs(dayjs(item.orderDate).subtract(16, 'hour')).isAfter(dayjs(endValue)) || (dayjs(dayjs(item.orderDate).subtract(16, 'hour')).isSame(endValue, 'date')))
      )
      ).length * 6000;
  };

  const calculateTotalDeposits = ({ savedDate }) => {
    // alert(dayjs(savedDate))
    return deposits
      .filter((e) => e.date === savedDate)
      .filter((item) =>
        ((dayjs(dayjs(item.orderDate).subtract(16, 'hour')).isBefore(dayjs(startValue)) || dayjs(dayjs(item.orderDate).subtract(16, 'hour')).isSame(startValue, 'date')))
        &&
        (dayjs(dayjs(item.orderDate).subtract(16, 'hour')).isAfter(dayjs(endValue)) || (dayjs(dayjs(item.orderDate).subtract(16, 'hour')).isSame(endValue, 'date'))
        )
      )
      .reduce((total, report) => {
        return (
          total +
          report.amount
        );
      }, 0);
  };


  const closeModal = () => {
    setIsModal(false);
  }

  return (
    <>
      {/* {isModal && orderDate ? <FinanceSuccess closeModal={closeModal} orderDate={orderDate} /> : null} */}
      <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
        <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: '#2c9961', color: '#fff' }}>
              <TableCell style={{ color: '#fff', fontSize: 16 }}>Id</TableCell>
              {/* <TableCell style={{ color: '#fff', fontSize: 16 }}>Харилцагч</TableCell> */}
              <TableCell style={{ color: '#fff', fontSize: 16 }}>Огноо</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Нийт</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Хүргэлт</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Татан авалт</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Тооцоо нийлэх дүн</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Тооцоо нийлсэн эсэх</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finance
              .filter((item) =>
                (dayjs(dayjs(item.createdAt)).isBefore(dayjs(startValue))
                  && dayjs(dayjs(item.createdAt)).isAfter(dayjs(endValue)))
                || (dayjs(dayjs(item.createdAt)).isSame(endValue, 'date')
                  && dayjs(dayjs(item.createdAt)).isSame(startValue, 'date'))
              )
              // .filter((e) => user ? (e.user && e.user === user) : e)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((e, index) => (
                <TableRow className='hover'
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {
                    console.log(e)
                  }
                  <TableCell align="left">{index + 1}</TableCell>
                  {/* <TableCell component="th" scope="row">
                    {users.filter((e) => user ? user === e.user : '')
                      .map((e) => (
                        e.name
                      ))}
                  </TableCell> */}
                  <TableCell component="th" scope="row">{e.savedDate}</TableCell>
                  <TableCell align="center">
                    {/* {endValue.toString()} */}
                    {new Intl.NumberFormat('en-Us').format
                      (calculateTotalPrice({ savedDate: e.savedDate }))
                      // {date: dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD').toString() }
                    }
                  </TableCell>
                  <TableCell align="center">
                    {/* {new Intl.NumberFormat('en-Us').format(calculateTotalDelivered({ date: dayjs(e.orderDate).format('YYYY-MM-DD').toString() }))} */}
                    {/* {new Intl.NumberFormat('en-Us').format(e.finances
                      .filter((e) => e.user === user)
                      .length * 6000)} */}

                    {new Intl.NumberFormat('en-Us').format
                      (calculateTotalDelivered({ date: e.savedDate }))
                      // {date: dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD').toString() }
                    }
                  </TableCell>
                  <TableCell align="center">
                    {/* {new Intl.NumberFormat('en-Us').format(calculateTotalDeposit({ date: dayjs(e.orderDate).format('YYYY-MM-DD').toString() }))} */}
                    {new Intl.NumberFormat('en-Us').format(calculateTotalDeposits({ savedDate: e.savedDate }))}
                  </TableCell>
                  <TableCell align="center">
                    {new Intl.NumberFormat('en-Us').format(calculateTotalPrice({ savedDate: e.savedDate }) - calculateTotalDelivered({ date: e.savedDate }))}
                  </TableCell>
                  <TableCell align="center">
                    {<div className='space-between'>
                      <p className={
                        depositsuccess.find((el) => el.date === e.savedDate)?.status === true
                          ? 'green-border' : 'red-border'}
                      >
                        {depositsuccess.filter((el) => el.date === e.savedDate)[0]?.status === true
                          ? 'Тооцоо нийлсэн' : 'Тооцоо нийлээгүй'}
                      </p>
                      {/* <MdEdit onClick={() => {
                        setIsModal(true);
                        setOrderDate(e.orderDate);
                      }} color='orange' size={25} /> */}
                    </div>
                    }
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={10}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}