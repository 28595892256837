import React, { useEffect, useState } from 'react'
import styles from './Items.module.css';
import ItemsTable from '../../../Utills/Table/ItemsTable';
import Navbar from '../../../Components/Navbar/Navbar';
import ItemsAddModal from '../../../Utills/Modal/ItemsAddModal';
import axios from 'axios';
import add_icon from '../../../Assets/uttils/add_button.svg';

const Items = () => {
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [search, setSearch] = useState('');

  const userInfo = JSON.parse(window.localStorage.getItem('useridturbo'));

  const closeModal = () => {
    setIsModal(false);
  }

  useEffect(() => {
    window.localStorage.setItem('side-id', 5);

    if (isLoading) {
      // alert(userInfo._id)
      axios.get('https://dev.turbodelivery.mn/post?sort=-createdAt&user=' + userInfo._id)
        .then((e) => {
          setPosts(e.data);
          setIsLoading(false)
        })
        .catch((e) => console.log(e));
    }
  }, [isLoading])

  return (
    <>
      {!isModal ? <Navbar title={'Бараа'} /> : <></>}
      {isModal ? <div className='abs'><ItemsAddModal closeModal={closeModal} /></div> : <></>}
      {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
      <div className={styles.container}>
        <div className='space-between' style={{ justifyContent: 'space-between', paddingRight: '2vw' }}>
          <label>
            Барааны нэрээр хайх:
            <input value={search} onChange={(e) => setSearch(e.target.value)} className='search' placeholder='Search' type="text" />
          </label>
          <button onClick={() => setIsModal(true)} className='button'><img src={add_icon} className='icon' alt="" /> Нэмэх</button>
        </div>
        <ItemsTable search={search} posts={posts} />
      </div>
    </>
  )
}

export default Items
