import React from 'react'

const CommentModal = ({ text, closeModal }) => {
    return (
        <div className='blur_container'>
            <div className="modal">
                <div className="two-line">
                    <h2>Тайлбар харах</h2>
                    <h2 onClick={closeModal} style={{ cursor: 'pointer' }}>X</h2>
                </div>
                <p>{text}</p>
                <div className="two-line">
                    <p></p>
                    <button onClick={closeModal} style={{ color: 'red' }} className='button-white'>Буцах</button>
                </div>
            </div>
        </div>
    )
}

export default CommentModal
