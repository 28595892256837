import { Tooltip } from '@mui/material';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { RiFileExcel2Line } from "react-icons/ri";

const ExportExcel = ({ excelData, fileName }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <>
            <Tooltip>
                <button style={{ color: 'green', display: 'flex', alignItems: 'center', gap: '10px' }} className='button-white' onClick={(e) => exportToExcel(fileName)}>
                    <RiFileExcel2Line color='green' />
                    Excel File
                </button>
            </Tooltip>
        </>
    )
}

export default ExportExcel;