import React from 'react'

const WarningModal = ({ closeModal, title }) => {
    return (
        <div className="blur_container">
            <div className='modal' style={{ width: '40vw', marginTop: '50px' }}>
                <div className='two-line' style={{ width: '40vw' }}>
                    <h2>Мэдэгдэл</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <p>{title[0].content}</p>
            </div>
        </div>
    )
}

export default WarningModal
